import { ethers } from "ethers";
import abi from '../abis/drugreceiptAbi';

const CONTRACT_ADDRESS = "0x03Ade60cE17FDefaCD5A28b1059aFC5760397D3B";
// const API_URL = 'http://localhost';
const API_URL = 'https://batcave.drx.store';

const getHeaders = () => {
    return {
        headers: {
            "Content-Type": "application/json",
        },
    };
};

const extractBody = async (res) => {
    if (!res.ok) {
        throw new Error(await res.text());
    } else {
        return res.json();
    }
};


let provider, signer, contractProvider;
if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner();
} else {
    // INFURA FALLBACK RPC HERE
    let fallbackProvider = ethers.getDefaultProvider("homestead", {});
}

export const updateProviderAndSigner = (library) => {
    if (library) {
        provider = new ethers.providers.Web3Provider(library.provider);
    } else {
        provider = new ethers.providers.Web3Provider(window.ethereum);
    }
    signer = provider.getSigner();
    contractProvider = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
};


export const getImageUrl = (tokenId, type = 0, rand = false) => {
    rand = true;
    let url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png${rand ? '?' + Math.random() : ''}`;
    switch (type) {
        case 1: {
            url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/transparent_image_${tokenId}_2160.png${rand ? '?' + Math.random() : ''}`;
            break;
        }
        case 2: {
            url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_2160.png${rand ? '?' + Math.random() : ''}`;
            break;
        }
        case 3: {
            url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_3840.png${rand ? '?' + Math.random() : ''}`;
            break;
        }
        case 4: {
            url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_landscape.png${rand ? '?' + Math.random() : ''}`;
            break;
        }
        case 5: {
            url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_portrait.png${rand ? '?' + Math.random() : ''}`;
            break;
        }
        case 6: {
            url = `https://drugreceipts.s3.eu-west-1.amazonaws.com/Lab/3840_Extended_No_Glow`;
            break;
        }
        case 7: {
            url = `https://drugreceipts.s3.eu-west-1.amazonaws.com/transparent_token_image_with_glow/transparent_image_${tokenId}_2160.png_with_glow.png`;
            break;
        }
        default: {
            url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png${rand ? '?' + Math.random() : ''}`;
            break;
        }
    }
    return url;
}

export const retrieveValidTokens = async (walletAddress) => {
    return await fetch(`${API_URL}/api/validmasktokens/${walletAddress}`, {
        method: 'GET',
        ...getHeaders(),
    })
        .then((response) => extractBody(response))
        .catch((e) => {
            console.error(e);
        });
}

export const checkClaimed = (data) => {
    let url = API_URL + `/api/valentineclaimedtoken/check`;
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        ...getHeaders(),
    }).then((res) => {
        return (res);
    });
}

export const setClaimedToken = (data) => {
    let url = API_URL + `/api/valentineclaimedtoken`;
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        ...getHeaders(),
    }).then((res) => {
        return (res);
    });
}

export const signMessagePublic = async (walletToAuthenticate, provider, context) => {
    let message = `This signature is to prove that you are the true owner of this wallet.`;
    let signature;

    provider = provider.toLowerCase()

    if (provider === "walletlink") {
        await sleep(400);
    }

    if (provider === "walletconnect" || provider === "walletlink") {
        signature = await context.library.provider.send("personal_sign", [
            ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
            walletToAuthenticate.toLowerCase(),
        ]);
        console.log(`${walletToAuthenticate}`)
    } else {
        signature = await signer.signMessage(message);
    }

    return { message, signature };
};


export const submitTicketForm = async (
    firstName,
    lastName,
    email,
    phoneNumber,
    tShirtSize,
    address1,
    address2,
    region,
    city,
    postalCode,
    country,
    walletAddress,
    tokenId,
    contractAddress,
    message,
    signature,
) => {


    return await fetch(`${API_URL}/api/mask`, {
        method: "POST",
        ...getHeaders(),
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneNumber,
            tShirtSize,
            address1,
            address2,
            region,
            city,
            postalCode,
            country,
            walletAddress,
            tokenId,
            contractAddress,
            message,
            signature,
        }),
    }).then((response) => extractBody(response));
};

export const getSizeStock = async () => {
    return await fetch(`${API_URL}/api/sizesremaining`, {
        method: "GET",
        ...getHeaders(),
    }).then((response) => extractBody(response));
}

const sleep = async (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};

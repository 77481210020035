import React from "react";

export default function MainPage({ children }) {

    return (
        <div className="max-w-5xl mx-auto sm:mt-16 px-5 lg:px-0">
            {children}
        </div>
    );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/DRx-Logo-Header.png";
import { GiHamburgerMenu } from 'react-icons/gi';

import Sidebar from "./Sidebar";
import discordImg from "../images/Social Icons/Discord.png";
import facebookImg from "../images/Social Icons/Facebook.png";
import instagramImg from "../images/Social Icons/Instagram.png";
import openseaImg from "../images/Social Icons/OpenSea.png";
import twitterImg from "../images/Social Icons/Twitter.png";

const SocialLinks = () => {
  const iconClassName = `inline-flex items-center justify-center text-white text-[17px]
  bg-[#78b2cf] rounded-[50%] leading-[17px] w-[34px] h-[34px]
  transition delay-0 hover:bg-[#5d8aa0] hover:scale-110 duration-200`;

  return (
    <div className="flex items-center content-center h-full gap-1 space-x-1 my-auto mt-2">
      <span>
        <a
          className={iconClassName}
          href="https://opensea.io/collection/drugreceipts"
          target="_blank"
          rel="noreferrer"
        >
          <img src={openseaImg}></img>
        </a>
      </span>
      <span>
        <a
          className={iconClassName}
          href="https://discord.gg/SUtQHy2CPM"
          target="_blank"
          rel="noreferrer"
        >
          <img src={discordImg}></img>
        </a>
      </span>
      <span>
        <a
          className={iconClassName}
          href="https://twitter.com/drugreceipts"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitterImg}></img>
        </a>
      </span>
      <span>
        <a
          className={iconClassName}
          href="https://www.instagram.com/drugreceipts/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagramImg}></img>
        </a>
      </span>
      <span>
        <a
          className={iconClassName}
          href="https://web.facebook.com/drugreceipts?_rdc=1&amp;_rdr"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebookImg}></img>
        </a>
      </span>
    </div>
  );
};

const MenuItems = ({ menuProps, externalLink, routes }) => {

  let items = menuProps.map((e, i) => {
    return (
      <div key={e}>
        {externalLink ? (
          <a href={routes[i]} target="_blank" rel="noreferrer">
            <div
              className="text-xl font-open-sans px-[13px] lg:py-[8px] text-[16px] 
              font-bold capitalize cursor-pointer text-[#fbdfea] hover:text-[#de477f]"
            >
              {e}
            </div>
          </a>
        )
          : menuProps[i] == "Drug wars" ? (
            <div className="dropdown inline-block group relative text-xl font-pressio-condensed font-medium uppercase text-[#000000] hover:text-[#FA8807] ">
              <button className="py-2 px-4 rounded inline-flex items-center">
                <span className="mr-1 uppercase">Drug wars</span>
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
              </button>
              <ul className="dropdown-menu absolute hidden group-hover:block text-black pt-1 text-[16px] ">
                <li className=""><a href="https://battlearena.drugreceipts.com/" className="rounded-t bg-gray-200 hover:bg-gray-400 hover:text-[#FA8807] py-2 px-4 block whitespace-no-wrap  cursor-pointer">Battle Arena</a></li>
                <li className=""><a href="https://drugreceipts.com/battle-bracket/" target="_blank" className="hover:text-[#FA8807] bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap">Battle Bracket</a></li>
                <li className=""><a className="hover:text-[#FA8807] bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="https://drugwars.drugreceipts.com/" target="_blank">Battlecard Builder</a></li>
                <li className=""><a className="hover:text-[#FA8807] bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="https://drugreceipts.com/wp-content/uploads/2022/09/Drug-Wars-Assets.zip" target="_blank">Propaganda Assets</a></li>
                <li className=""><a className="hover:text-[#FA8807] rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="https://drugreceipts.com/drugwars/">About</a></li>
              </ul>
            </div>
          ) : (
            <Link
              to={routes[i]}
              target="_self"
              className={routes[i] === "#" ? "opacity-100" : ""}
            >
              <div
                className="text-xl font-open-sans px-[13px] py-[8px], text-[20px] 
                font-bold uppercase cursor-pointer text-[#000000] "
              >
                {e}
              </div>
            </Link>
          )}
      </div>
    );
  });
  return items;
};

const MenuItemsMobile = ({ menuProps, externalLink, routes }) => {
  let items = menuProps.map((e, i) => {
    return (
      <div key={e}>
        {externalLink || menuProps[i] == "Drug inspector" || menuProps[i] == "Home" || menuProps[i] == "Drug store" ? (
          <a href={routes[i]} target="_blank" rel="noreferrer">
            <div
              className="text-xl font-Montserrat px-[13px] lg:py-[8px] text-[16px] 
              font-medium capitalize cursor-pointer text-[#de477f] hover:text-[#de477f]"
            >
              {e}
            </div>
          </a>
        ) :
          menuProps[i] == "Drug wars" ? (
            <div>
              <div
                className="text-xl font-pressio-condensed px-[13px] lg:py-[8px] text-[20px] 
              font-medium uppercase cursor-pointer text-[#F3D0B4] "
              >
                <div><a className="hover:text-[#FA8807] cursor-pointer">Battle Arena</a></div>
                <div><a className="hover:text-[#FA8807]" href="https://drugreceipts.com/battle-bracket/" target="_blank">Battle Bracket</a></div>
                <div><a className="hover:text-[#FA8807]" href="https://drugwars.drugreceipts.com/" target="_blank">Battlecard Builder</a></div>
                <div><a className="hover:text-[#FA8807]" href="https://drugreceipts.com/wp-content/uploads/2022/09/Drug-Wars-Assets.zip" target="_blank">Propaganda Assets</a></div>
                <div><a className="hover:text-[#FA8807]" href="https://drugreceipts.com/drugwars/">About</a></div>
              </div>
            </div>
          ) : (
            <Link
              to={routes[i]}
              target="_self"
              className={routes[i] === "#" ? "" : ""}
            >
              <div
                className="text-xl font-pressio-condensed px-[13px] py-[8px], text-[20px] 
                font-medium uppercase cursor-pointer text-black"
              >
                {e}
              </div>
            </Link>
          )}
      </div>
    );
  });
  return items;
};

export default function Header({ menuItems, rightArea, showSocial, externalLink, routes }) {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };


  return (
    <div className="">
      <div className="bg-[#560a0f] px-5 ">
        <div className="flex max-w-5xl items-center justify-between py-3 mx-auto sm:px-0">
          <div className="py-2">
            <Link to='/'>
              <img src={logo} className="w-[168px] hover:cursor-pointer" />
            </Link>
          </div>
          <div className="hidden px-10 sm:flex justify-evenly grow items-center">
            <MenuItems menuProps={menuItems} externalLink={externalLink} routes={routes} />
          </div>
          <div className="hidden sm:block">{rightArea && rightArea}</div>
          {showSocial ? (
            <div className="hidden sm:block">
              <SocialLinks />
            </div>
          ) : null}
          <div className="sm:hidden">
            {!isSidebarOpen &&
              <button className={`inline-flex items-center justify-center text-[#F3D0B4]
              text-[25px] transition delay-0 hover:scale-110 duration-200`} onClick={toggleSidebar}>
                <GiHamburgerMenu />
              </button>}
          </div>
          {isSidebarOpen &&
            <div>
              <Sidebar
                toggleSidebar={toggleSidebar}
                isSidebarOpen={isSidebarOpen}
                menuItems={
                  <MenuItemsMobile
                    menuProps={menuItems}
                    externalLink={externalLink}
                    routes={routes}
                  />
                }
                socialIcons={<SocialLinks />}
              />
            </div>}
        </div>
      </div>
    </div>
  );
}

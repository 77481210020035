
import { useEffect, useState, useLayoutEffect } from "react";
import { useWeb3React } from "@web3-react/core";

import * as API from "../utils/utils";
import Web3 from 'web3';
import hoodie from "../images/hoodie.png";
import Header from "../components/Header";
import MainPage from "../components/MainPage";
import ProcessingModal from "../components/ProcessingModal.js";

import drugreceiptAbi from '../abis/drugreceiptAbi';
import greetingcardAbi from '../abis/greetingcardAbi';

import logo from "../images/DRx-logo.svg";
import claimNowBtnImg from "../images/button/claimNow.png";

const progressMessage = [["HEARTS ARE BREAKING…", "Please wait, Drug Snuggler!"], ["OH SNUG!", "You’ve already minted your DRx Valentine’s Day greeting card."], ["SNUG YEAH!", "You’ve minted your DRx Valentine’s Day card. Welcome to the Broken Hearts Club!"]];

export default function Claim() {

    const wallet = useWeb3React();
    const web3 = new Web3(wallet.library.provider);
    const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
    const [message, setMessage] = useState(progressMessage[0]);
    const [tokenExist, setTokenExist] = useState(false);

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    useLayoutEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const checkWallet = async () => {
            if (wallet.active && wallet.account != undefined) {
                const drugContractAddress = "0x00ea2894fe840f105ab99a8f8f75b1f17e94843a";
                const drugContract = new web3.eth.Contract(drugreceiptAbi, drugContractAddress);
                const _account = await web3.eth.getAccounts();
                drugContract.methods.balanceOf(_account[0]).call().then(balanceOfDrugToken => {
                    if (balanceOfDrugToken > 0) {
                        setTokenExist(true);
                    } else {
                        setTokenExist(false);
                    }
                }).catch(err => {
                    console.log(err);
                    setTokenExist(false);
                });
            }
        };
        checkWallet();
    }, [wallet]);

    const doClaim = async () => {
        setIsProcessingModalOpen(true);
        setMessage(progressMessage[0]);

        const GreetingcardAddress = "0x9789353350aCC2583C2f1786955eE5057DBAd01C";

        const greetingcardContract = new web3.eth.Contract(greetingcardAbi, GreetingcardAddress);

        const _account = await web3.eth.getAccounts();

        let data = {
            walletAddress: _account[0]
        }
        let res = await API.checkClaimed(data);
        if (res.ok) {
            res = await res.json();
            // console.log("value", res);
            if (res) {
                setIsProcessingModalOpen(true);
                setMessage(progressMessage[1]);
            } else {
                greetingcardContract.methods.getClaimStatus(1, _account[0]).call().then(async (mintStatus) => {
                    // console.log("mintStatus", mintStatus);
                    if (!mintStatus) {
                        // let res1 = await API.setClaimedToken(data);
                        // setMessage(progressMessage[2]);

                        greetingcardContract.methods
                            .normalMint()
                            .send({ from: _account[0] })
                            .then(async (tx) => {
                                // console.log("Tx", tx);
                                let res1 = await API.setClaimedToken(data);
                                setMessage(progressMessage[2]);
                            }).catch(err => {
                                console.log(err);
                                setIsProcessingModalOpen(false);
                            });
                    } else {
                        setIsProcessingModalOpen(true);
                        setMessage(progressMessage[1]);
                    }
                }).catch(err => {
                    console.log(err);
                    setIsProcessingModalOpen(false);
                });
            }
        } else {
            res = await res.json();
            console.log(res);
        }
    }

    return (
        <div className="h-full w-full relative">
            {isProcessingModalOpen && (
                <ProcessingModal
                    setIsProcessingModalOpen={setIsProcessingModalOpen}
                    message={message}
                />
            )}
            <div className="">
                <div className="sticky top-0 z-[30]">
                    <Header
                        menuItems={[
                            "Main Website",
                            "Opensea Collection",
                            "Drug store"
                        ]}
                        rightArea={null}
                        showSocial={true}
                        externalLink={true}
                        routes={["https://drugreceipts.com/", "https://opensea.io/collection/drugreceipts", "https://drx.store/"]}
                    />
                </div>

                <MainPage>
                    <div className="flex flex-col h-full mx-auto mt-12 sm:pb-12 ">

                        <div className="flex items-center justify-center w-full h-full gap-8 ">
                            <div className="w-full lg:w-[48%] my-10">
                                <img className="w-[182px] my-8 " src={logo} />
                                <div className="font-pressio-condensed text-[#560a0f] text-[44px] leading-[46px] font-semibold">
                                    <p>DO YOU THINK YOU’RE BETTER OFF ALONE?</p>
                                </div>
                                <p className="font-open-sans font-semibold text-[#560a0f] text-[20px] mt-3">
                                    Keep the candy and the flowers, this heartbreakingly sweet digital Valentine’s Day card is available only for the lonely—and it is reserved exclusively for our Drug Snugglers. Who said nobody loves you?
                                    <p className="mt-4">Connect your wallet to join the DRx Broken Hearts Club.</p>
                                </p>
                                <div className="font-Montserrat font-semibold text-[#560a0f] text-[20px] mt-6">
                                    This claim is no longer active.
                                </div>
                                {/* <div className={(tokenExist ? "hidden" : "block")}>
                                    <img className="w-[182px] my-8 " src={logo} />
                                    <div className="font-pressio-condensed text-[#560a0f] text-[44px] leading-[46px] font-semibold">
                                        <p>DO YOU THINK YOU’RE BETTER OFF ALONE?</p>
                                    </div>
                                    <p className="font-open-sans font-semibold text-[#560a0f] text-[20px] mt-3">
                                        Keep the candy and the flowers, this heartbreakingly sweet digital Valentine’s Day card is available only for the lonely—and it is reserved exclusively for our Drug Snugglers. Who said nobody loves you?
                                        <p className="mt-4">Connect your wallet to join the DRx Broken Hearts Club.</p>
                                    </p>
                                    <div className="font-Montserrat font-semibold text-[#560a0f] text-[20px] mt-3">
                                        Oh snug! No DRx tokens found. 
                                        <p className="underline cursor-pointer"><a href="https://opensea.io/collection/drugreceipts" target="_blank">Become a Drug Snuggler today!</a></p>
                                    </div>
                                </div>
                                <div className={(tokenExist ? "block" : "hidden")}>
                                    <img className="w-[182px] mt-8 " src={logo} />
                                    <div className="font-pressio-condensed text-[#560a0f] text-[50px]  leading-[58px] lg:text-[70px] leading-[78px] font-medium mt-[40px]">
                                        HAPPY SNUGGIN’ VALENTINE’S DAY. OR WHATEVER.
                                    </div>
                                    <div className="mt-[30px]">
                                        <div className="flex gap-4 text-[26px] max-w-[350px]">
                                            <div className="w-full">
                                                <img src={claimNowBtnImg} onClick={() => { doClaim() }} className="hover:scale-105 cursor-pointer ml-0 sm:-ml-[18px]"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>


                            {dimensions.width > 900 && (
                                <img
                                    src={hoodie}
                                    className="w-[52%] my-10"
                                    alt="Rex Character"
                                    width={470}
                                    height={505}
                                />
                            )}
                        </div>
                    </div>
                </MainPage>
            </div>
        </div>

    )
}